.cart-list {
  margin-bottom: 6rem;
  max-width: 1440px;
  background-color: #f3f3f3;
  padding: 48px;
  border-radius: 1rem;
}
.cart-list--blank {
  margin-bottom: 6rem;
  max-width: 1440px;
  background-color: #f3f3f3;
  padding: 48px;
  border-radius: 1rem;
}
.cart-list__title {
  margin-top: 0;
  color: black;
}
.cart-list__result {
  display: flex;
  flex-direction: column;
}
.cart-list__sum {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  transition: all 1s ease-in;
}
.cart-list__button {
  display: flex;
  box-sizing: border-box;
  max-width: max-content;
  margin-left: auto;
  border-radius: 0.7rem;
  height: 2rem;
  border: none;
  background-color: #ffd600;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  outline: 1px solid rgb(107, 107, 107);
  animation: animate_reduce 2s infinite;
}
.cart-list__button:hover {
  background-color: #e2bc00;
  outline: 1px solid black;
  animation: none;
}
.cart-list__button:active {
  background-color: #e2bc00;
}
.cart-list__link--blank {
  align-items: center;
    margin-top: 12px;
    appearance: none;
    background-color: #fff;
    border-radius: 1rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    width: 180px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms, background-color 0.2s cubic-bezier(0, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform, opacity;
    z-index: 0;
}
.cart-list__link--blank:hover {
  background-color: #ffe600;
  color: #000;
}

.cart-list__link--blank:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.cart-list__link--blank:focus {
  outline: none;
  border: 2px solid #ffe600;
}

.cart-list__link--blank:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.cart-list__link--blank:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.cart-list__link--blank:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.cart-list__link--blank:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}
@keyframes animate_reduce {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0.95, 0.95, 0.95);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@media (max-width: 700px) {
  .cart-list--blank {
    padding: 16px 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cart-list__title--blank {
      margin-left: 0;
    }
  }
}
@media (max-width: 600px) {
  .cart-list {
    padding: 16px 0;
    border-radius: 0;
  }
  
  .cart-list__title {
    margin-left: 16px;
  }
  .cart-list__sum {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 16px;
  }
  .cart-list__button {
    margin-right: 1rem;
  }
  .cart-list__text {
    padding: 1rem;
  }
}
@media (max-width: 482px) {
  .cart-list__sum {
    font-size: 1rem;
    font-weight: 600;
  }
}
