.count-buttons {
  display: flex;
  font-size: 1.2rem;
  justify-content: space-between;
  border-radius: 1rem;
  margin-top: 12px;
  height: 50px;
  width: 180px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;

}

.count-cart-buttons {
  margin-top: 0.5rem;
  display: flex;
  height: 100%;
  font-size: 1.5rem;
  justify-content: center;
  border-radius: 0.5rem;
  // outline: 1px solid black;
  transition: 2s ease-in-out;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  .count-buttons__button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .count-buttons__button:active {
    background-color: rgba(0, 0, 0, 0.2);
    
  }
}

.count-buttons__input {
  width: 2rem;
  text-align: center;
  outline: 0;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.count-buttons__button {
  cursor: pointer;
  border: 0;
  outline: 0;
  background-color: transparent;
  width: 3.5rem;
  border-radius: inherit;
  transition: background-color 0.1s ease;
  transition: .3s ease-in-out;
}
.count-buttons__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.count-buttons__button:active {
  background-color: rgba(0, 0, 0, 0.2);
  
}


.count-buttons__button.count-buttons__button-decrease .count-buttons__icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count-buttons__button.count-buttons__button-increase .count-buttons__icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-buttons__button.count-buttons__button-decrease
  .count-buttons__icon::before {
  content: '-';
  scale: 1.5;
  font-style: normal;
}

.count-buttons__button.count-buttons__button-increase
  .count-buttons__icon::before {
  content: '+';
  scale: 1.1;
  font-style: normal;
}
@media (max-width: 482px) {
  .count-buttons {
    width: 100%;
  }
  .count-cart-buttons {
    margin-top: 0;
    width: 100%;
  }
}
