.header-container {
  width: 100%;
  max-width: 100%;
}

.header {
  transform: translateY(0);
  position: fixed;
  z-index: 1500;
  height: 4.5rem;
  transition: transform 0.3s;
  width: 100vw;
  transform: translateY(-4.5rem);
  user-select: none;
}

.header.header--visible {
  transform: translateY(0);
}

.header__image {
  width: 15rem;
}

.header__content {
  display: flex;
  width: 100%;
  height: inherit;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 32px;
}

.header__navigation {
  display: flex;
  justify-content: space-between;
  max-width: 25rem;
  flex-wrap: nowrap;
}

.header__gradient {
  position: absolute;
  width: 100%;
  height: 10rem;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.595) 6.67%,
    rgba(0, 0, 0, 0.579) 13.33%,
    rgba(0, 0, 0, 0.551) 20%,
    rgba(0, 0, 0, 0.512) 26.67%,
    rgba(0, 0, 0, 0.461) 33.33%,
    rgba(0, 0, 0, 0.401) 40%,
    rgba(0, 0, 0, 0.334) 46.67%,
    rgba(0, 0, 0, 0.266) 53.33%,
    rgba(0, 0, 0, 0.199) 60%,
    rgba(0, 0, 0, 0.139) 66.67%,
    rgba(0, 0, 0, 0.088) 73.33%,
    rgba(0, 0, 0, 0.049) 80%,
    rgba(0, 0, 0, 0.021) 86.67%,
    rgba(0, 0, 0, 0.005) 93.33%,
    transparent
  );
  transition: opacity 0.3s;
}

.header__gradient.header__gradient--visible::after {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.header__gradient::after {
  transition: opacity 0.3s ease;
  position: absolute;
  content: '';
  display: block;
  height: 12rem;
  width: 100%;
  opacity: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.95),
    rgba(0, 0, 0, 0.934) 14.24%,
    rgba(0, 0, 0, 0.902) 25.77%,
    rgba(0, 0, 0, 0.857) 34.98%,
    rgba(0, 0, 0, 0.799) 42.23%,
    rgba(0, 0, 0, 0.733) 47.9%,
    rgba(0, 0, 0, 0.658) 52.37%,
    rgba(0, 0, 0, 0.579) 56%,
    rgba(0, 0, 0, 0.496) 59.18%,
    rgba(0, 0, 0, 0.412) 62.27%,
    rgba(0, 0, 0, 0.329) 65.65%,
    rgba(0, 0, 0, 0.249) 69.69%,
    rgba(0, 0, 0, 0.173) 74.78%,
    rgba(0, 0, 0, 0.106) 81.27%,
    rgba(0, 0, 0, 0.047) 89.56%,
    transparent
  );
}

.header__navbutton.active {
  color: white;
}

.background-image {
  display: block;
  max-width: 100%;
  min-width: 700px;
  height: 320px;
  background-image: url('../../assets/img/firebg1.jpg');
  background-size: cover;
  background-position: -50%;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 -30px 25px 0 black inset;
}

.burger {
  display: flex;
  align-items: center;
  height: 51px;
  padding-right: 16px;
  color: gray;
}

.burger .navigation-wrapper {
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 360px;
  background-color: rgb(41, 41, 41);
  padding: 75px 30px 30px 30px;
  z-index: 999;
  transform: translateX(100%);
  transition: transform 0.2s ease;
  overflow: hidden;
}
.burger.burger--expanded .navigation-wrapper {
  transform: translateX(0);
}

.burger-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  z-index: 999;
}

.burger-bg.burger--expanded {
  pointer-events: all;
  opacity: 1;
}
.navigation-burger__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;
}

.navigation-burger__item a {
  display: block;
  text-align: start;
  padding: 20px 0;
}

.navigation-burger__item a.active {
  color: white;
}

.navigation-burger__item a:hover {
  color: white;
}
.navigation-wrapper {
  display: flex;
  width: 100%;
  justify-content: end;
  border-bottom: 2px solid grey;
  justify-content: center;
  // box-shadow: 0px 0px 8px 8px rgba(41, 41, 41, 1);
}

.navigation-wrapper.no-border {
  border: none;
}
@media (max-width: 900px) {
  .header__content {
    padding: 0 0 0 32px;
  }
}
@media (max-width: 700px) {
  .background-image {
    min-width: 320px;
  }
  .burger .navigation-wrapper {
    max-width: 100%;
  }
}