.order-modal {
  background-color: rgb(255, 255, 255);
  max-height: 700px;
  border-radius: 20px;
  max-width: 550px;
}
.order-modal__items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 40px 40px 40px;
}
.order-modal__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  line-height: 16px;
  input[type='text'] {
    font-family: inherit; /* 1 */
    font-size: inherit; /* 1 */
    line-height: inherit; /* 1 */
    margin: 0; /* 2 */
  }
  .order-modal__item-label {
    display: block;
    margin-bottom: 0.25rem;
  }
  .order-modal__item-input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .order-modal__item-input::placeholder {
    color: #212529;
    opacity: 0.4;
  }
  .order-modal__item-input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #bdbdbd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
  }
}
.order-modal__item-age {
  margin-bottom: 20px;
  .order-modal__item-label {
    margin-left: 10px;
  }
}
.order-modal__sum {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .order-modal__submit {
    margin-left: auto;
  }
}
