.header__cart {
  position: relative;
}

.header__cart:hover > .cart__count {
  background-color: #fff;
}

.cart__count {
  transition: background-color 0.2s;
  display: flex;
  text-decoration: none;
  color: rgb(0, 0, 0);
  background-color: rgb(209, 209, 209);
  min-width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  text-align: center;
  vertical-align: middle;
  border-radius: 50px;
  position: absolute;
  right: -10px;
  top: 5px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
}

.active > .header__cart > .cart__count {
  background-color: #fff;
}

.header__cart-list {
  transition: 0.2s;
  position: absolute;
  right: 30px;
  top: 50px;
  display: block;
  max-width: 600px;
  max-height: 640px;
  background-color: #f3f3f3;
  border-radius: 1rem 0rem 1rem 1rem;
  animation: showBlock 0.5s linear forwards;
  outline: 1px solid black;
}

.header__cart-list-items {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 500px;
}

.header__cart__sum {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  max-height: 55px;
}
.cart-list__sum-price {
  p {
    text-align: end;
    margin: 0 0 10px auto;
  }
}

.header__cart-list.hide {
  animation: removeBlock 0.5s linear forwards;
}
.header__cart-button {
  display: flex;
  box-sizing: border-box;
  max-width: max-content;
  margin-left: auto;
  border-radius: 0.7rem;
  height: 2rem;
  border: none;
  background: linear-gradient(-45deg, #a39039, #ffd600, #cec10a, #ffd600);
  background-size: 400% 400%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  outline: 1px solid rgb(107, 107, 107);
  animation: gradient 7s infinite;
}
.header__cart-button:hover {
  background-position: 50% 50%;
  box-shadow: 0 0 5px 3px rgba(112, 112, 112, 0.6);
  animation: none;
  outline: 1px solid #ffd600;
}
.header__cart-button:active {
  background: #e2bc00;
}

@keyframes gradient {
  0% {
    background-position: 25% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 25% 50%;
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes removeBlock {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (min-width: 900px) {
  
}
