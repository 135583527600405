.icon-treshold img {
  transition: 0.2s ease-in;
  cursor: pointer;
}

.icon-treshold:hover img {
  scale: 1.1;
  animation: swing 1s ease-in-out alternate;
  transform-origin: 50% 80%;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-10deg);
  }
  66% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
