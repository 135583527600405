.footer {
  user-select: none;
  position: relative;
  width: 100%;
  max-height: 200px;
  background-color: rgb(32, 32, 32);
  color: gray;
  box-shadow: 0px -10px 15px 20px rgb(32, 32, 32);
}
.footer__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer__socials-icons {
  height: 30px;
  width: 100%;
}
.footer__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  a {
    text-decoration: none;
    font-size: 0.9rem;
    color: white;
  }
  a:hover {
    color: rgb(207, 206, 206);
  }
}
.footer__message-question {
  font-size: 0.8rem;
}
.footer__info {
  display: flex;
  max-height: 124px;
}
.footer__copyright {
  font-size: 0.7rem;
}
.footer__copyright-text {
  font-size: 0.6rem;
  a {
    text-decoration: none;
    color: gray;
  }
}
.footer__copyright-left {
  margin-left: 32px;
  max-width: 40%;
  margin-bottom: 32px;
}
.footer__contacts-right {
  margin-left: auto;
  margin-right: 32px;
  margin-bottom: 32px;
  p a:last-child {
    margin-left: 20px;
  }
  p a {
    font-size: 0.9rem;
    text-decoration: none;
    color: white;
  }
  p {
    margin-top: 0;
    font-size: 0.7rem;
  }
  p a:hover {
    color: rgb(207, 206, 206);
  }
}

.footer__socials-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px; 
}
@media (max-width: 700px) {
  .footer {
    max-height: 100%;
  }
  .footer__info {
    flex-direction: column;
    flex-direction: column-reverse;
    max-height: 100%;
  }
  .footer__copyright-left {
    max-width: calc(100% - 48px);
    margin: 0 16px 40px 32px;
  }
  .footer__contacts-right {
    margin: 32px auto; 
  }
} 
