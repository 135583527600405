.modal-wrapper {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-content {
  cursor: default;
  margin: auto;
  vertical-align: middle;
  box-sizing: content-box;
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  border: 0;
  outline: 0;
  color: rgb(219, 219, 219);
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s ease;
}

.modal-close:hover,
.modal-wrapper:hover .modal-content:not(.modal-content:hover) > .modal-close {
  color: white;
}
