@font-face {
  font-family: 'Roboto';
  src: url('../src/assets/fonts/Roboto/Roboto-Medium.ttf');
}

/* html {
  scroll-behavior: smooth;
} */

* {
  box-sizing: border-box;
}

body {
  background-color: black;
  margin: 0;
  margin-right: calc(-1 * (100vw - 100%)) !important;
  overflow-x: hidden;
  padding: 0;
  font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
body.burger--expanded {
  overflow: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: transparent;
  -webkit-appearance: none;
}
a[href^=tel]{
  color: #000;
}
button {
  color: #000;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 700px;
  min-height: 100vh;
  z-index: 1;
  background-color: rgb(0, 0, 0);
}

body.modal-open {
  overflow-y: hidden;
}
@media (max-width: 700px) {
  .app-container {
    min-width: 320px;
  }
  html, body {
    overflow-x: hidden;
  }
  
}
