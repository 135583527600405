.main-content {
  color: rgb(218, 218, 218);
}

.main-content__delivery {
  margin-top: 2rem;
  display: flex;
  margin-bottom: 6rem;
  justify-content: center;
}

.main-content__delivery-points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  border-top: 1px solid gray;
  border-left: 2px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 10px 0 0 10px;
  span {
    font-size: 20px;
    line-height: 70px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
  }
}

.main-content__delivery-map {
  max-width: 100%;
  min-width: 700px;
  min-height: 500px;
}

@media (max-width: 1290px) {
  .main-content__delivery-map {
    max-width: 100%;
    min-width: 400px;
    min-height: 500px;
  }
}
@media (max-width: 1000px) {
  .main-content__delivery {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    justify-content: center;
    flex-direction: column-reverse;

  }
}
@media (max-width: 700px) {
  .main-content__delivery-points {
    display: block;
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    margin: 0px 10px;
    width: calc(100% - 20px);
    padding: 0;
    span {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
    
  }
  .main-content__delivery-map {
    min-width: 320px;
  }
}
