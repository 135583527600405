.store-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease;
}

.store-item__image {
  width: 60px;
  height: 60px;
}

.store-item__info {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 1rem;
  margin-left: 16px;
  
}

.store-item__info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.store-item__title {
  font-size: 16px;
  margin: 0;
  color: black
}

.store-item__description {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.store-item__video-link {
  text-decoration: none;
  color: gray;
}

.store-item__buy-container {
  margin-left: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

// .store-item__buy-button {
//   border-radius: 1rem;
//   border: 0;
//   font-size: 16px;
//   margin-top: 12px;
//   cursor: pointer;
//   height: 50px;
//   width: 180px;
//   background-color: transparent;
//   // outline: 2px solid rgba(255, 255, 255, 0.9);
//   box-shadow: -2px 0px 0px 0px #7ee854, 2px 0px 0px 0px #efcb0a;
//   transition: background-color 0.1s ease;
//   background-color: #efcb0a;
//   background-image: linear-gradient(135deg, #7ee854 10%, #bad444 50%, #efcb0a 100%);
//   color: #000;
//   transition: all 0.5s ease;
// }

.store-item:hover {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
}


.store-item__buy-button {
  align-items: center;
  margin-top: 12px;
  appearance: none;
  background-color: #fff;
  border-radius: 1rem;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  width: 180px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms, background-color .2s cubic-bezier(0, 0, .2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform,opacity;
  z-index: 0;
}

.store-item__buy-button:hover {
  background-color: #ffe600;
  color: #000;
}

.store-item__buy-button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.store-item__buy-button:focus {
  outline: none;
  border: 2px solid #ffe600;
}

.store-item__buy-button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.store-item__buy-button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.store-item__buy-button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.store-item__buy-button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.store-item__buy-button:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

// .store-item__buy-button:hover {
//   background-color: #da4f6e;
//   box-shadow: -2px 0px 5px 2px #b33f58, 0px 2px 5px 2px #9e8910;
//   background-image: linear-gradient(60deg, #b33f58 10%, #b46d34 50%, #9e8910 100%);
// }
// .store-item__buy-button:active {
//   box-shadow: -2px 0px 5px 2px #8a2f43, 0px 2px 5px 2px #81700e;
//   background-image: linear-gradient(60deg, #8a2f43 10%, #995c2b 50%, #81700e 100%);
// }

.store-item__buy-button:disabled {
  outline: none;
  color: gray;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  cursor: auto;
}

.store-item__buy-button:disabled:hover {
  background-color: initial;
}

.store-item__price {
  font-size: 20px;
}

.store-item__money-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row-reverse;
}
.store-item__about {
  width: 60%;
  margin-left: 32px;
}
.store-item__buttons {
  width: 100%;
}

@media (max-width: 482px) {
  .store-item {
    border-radius: 0;
    padding: 8px 0;
  }
  .store-item__info {
    padding: 0 0 0 16px;

  }
  .store-item__info-wrapper {
    flex-direction: column;
    align-items: start;
  }
  .store-item__buy-container {
    flex-direction: row;
    width: 100%;
  }
  .store-item__money-container {
    align-items: start;
    flex-direction: row;
  }
  .store-item__buttons {
    width: 30%;
  }
}
@media (max-width: 380px) {
  .store-item__buy-container {
    flex-direction: column;
    align-items: start;
  }
  .store-item__buttons {
    margin: 0 32px;
    width: 50%;
  }
}