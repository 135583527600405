.select {
  position: relative;
  margin-right: 20px;
  user-select: none;
  margin-left: 20px;
}
.select.selected::before {
  display: none;
  }

.select__title {
  position: relative;
  cursor: pointer;
  height: 50px;
  width: 180px;
  font-size: 16px;
  border-radius: 1rem;
  outline: none;
  border: none;
  text-align: left;
  padding-left: 20px;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0, rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  user-select: none;
}
.select::before {
  content: '';
  cursor: pointer;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  // border-top: 6px solid black;
  border-bottom: 6px solid rgba(0, 0, 0, 0.5);
  z-index: 6;
  right: 20px;
  top: 22px;
  rotate: 180deg;
  // animation: scaleIcon 2s infinite linear;
  transition: scale .3s;
}
.select:has(> .select__dropdown.active)::before {
  scale: 1  -1;
  top: 22px;
}
@keyframes scaleIcon {
  0% {transform: rotate(0deg)}
  // 50% {transform: rotate(360deg)}
  100% {transform: rotate(360deg)}
}

.select__dropdown {
  opacity: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 52px;
  background-color: white;
  width: 100%;
  transition: opacity 0.3s ease, z-index 0.3s ease;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0, rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  z-index: -2;
}
.select .active {
  opacity: 1;
  z-index: 2;
}

.select__item {
  padding: 10px;
  cursor: pointer;
}
.select__item:hover {
  background-color: #dbdbdb;
}
