.store-item__remove-button {
  border: none;
  box-sizing: border-box;
  background-color: transparent;
}
.cart-item__price {
  font-size: 20px;
}
.cart-item__money-container {
  display: flex;
  width: 9rem;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row-reverse;
}
.cart-item__buy-container {
  margin-left: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  min-width: 9rem;
}
@media (max-width: 482px) {
  .store-item {
    box-sizing: content-box;
  }
  .cart-item__buy-container {
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 0 4% 0 30px;
  }
  .cart-item__money-container {
    flex-direction: row;
    align-items: center;
    width: 90px;
    justify-content: space-between;
  }
  .cart-item__price-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

  }
  .store-item__title {
    font-size: 14px;
  }
  .cart-item__price {
    font-size: 15px;
}
}
