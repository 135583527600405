.category-spinner {
  margin-top: 1rem;
  margin-bottom: 2rem;
  user-select: none;
}
.category-spinner__slider {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
}
