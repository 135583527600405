.burger__button {
    cursor: pointer;
    display: flex;
    background-color: black;
    align-items: center;
    text-align: end;
    margin-left: auto;
    border-radius: 50%;
    padding: 9px;
    box-shadow: 0px 0px 12px 4px rgba(255, 255, 255, 0.4);
  }
  
  .burger__button svg path {
    stroke: white;
    transition:
      transform 0.3s ease,
      stroke 0.2s ease;
  }
  
  .burger__button:hover svg path:nth-child(1) {
    transform: rotate(30deg) translateY(-9px) translateX(6px) scaleX(0.7);
  }
  
  .burger__button:hover svg path:nth-child(3) {
    transform: rotate(-30deg) translateY(6px) translateX(-6px) scaleX(0.7);
  }
  
  .burger__button svg {
    transition: transform 0.2s ease;
  }
  .burger__button:hover {
    box-shadow: 0px 0px 12px 8px rgba(255, 255, 255, 0.6);
  }
  .burger__button:active {
    box-shadow: 0px 0px 12px 8px rgba(218, 218, 218, 0.6);
  }
  
  .burger__button:hover svg {
    transform: rotate(180deg);
  }
  .burger__button:hover svg path {
    stroke: white;
  }
  .burger__button:active svg path {
    stroke: rgb(218, 218, 218);
  }