.close-button {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 20px;
    width: max-content;
    padding: 10px;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    transform: scale(1.2);
  }
  
  .close-button__content {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: transparent;
    position: relative;
  }
  
  .close-button__content::before,
  .close-button__content::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: white;
  }
  
  .close-button__content::before {
    transform: rotate(45deg);
  }
  
  .close-button__content::after {
    transform: rotate(-45deg);
  }