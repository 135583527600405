.not-found {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row-reverse;
  padding: 100px;
  border-radius: 2rem;
  max-width: 1200px;
  user-select: none;
  justify-content: space-between;
}
.not-found__title {
  color: #ffe600;
  font-size: 180px;
  margin: 0;
  width: 30%;
}
.not-found__text-container {
  width: 60%;
}
.not-found__text-title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
}
.not-found__text {
  font-size: 18px;
}

.not-found__text-link {
  align-items: center;
  margin-top: 12px;
  appearance: none;
  background-color: #fff;
  border-radius: 1rem;
  border-style: none;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0,
    rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  width: max-content;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition:
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms,
    transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    background-color 0.2s cubic-bezier(0, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform, opacity;
  z-index: 0;
}

.not-found__text-link:hover {
  background-color: #ffe600;
  color: #000;
}

.not-found__text-link:active {
  box-shadow:
    0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
  background-color: #ceb900;
}

.not-found__text-link:focus {
  outline: none;
  border: 2px solid #ffe600;
}

.not-found__text-link:not(:disabled) {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.not-found__text-link:not(:disabled):hover {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.not-found__text-link:not(:disabled):focus {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.not-found__text-link:not(:disabled):active {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}
@media (max-width: 900px) {
  .not-found {
    flex-direction: column;
    max-width: max-content;
    align-items: center;
    padding: 10%;
  }
  .not-found__title {
    width: max-content;
  }
  .not-found__text-container {
    width: 90%;
  }
}
@media (max-width: 700px) {
  .not-found {
    border-radius: 0;
    margin: 0 0 60px 0;
    padding: 16px;
  }
  .not-found__text-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .not-found__text {
    font-size: 14px;
  }
  .not-found__title {
    font-size: 120px;
  }
  .not-found__text-title {
    font-size: 22px;
  }
}
