.scroll-top-button-container {
  position: fixed;
  z-index: 900;
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease-in;
}

.scroll-top-button__button {
  background-color: #ffe600;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: box-shadow 0.1s linear;
  color: black;
  border: none;
  outline: none;
}



.scroll-top-button__button:hover {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.5);
  background-color: #e2cc00;
}
