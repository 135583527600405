.price-message {
  left: 0px;
  height: max-content;
  right: 0px;
  display: flex;
  position: fixed;
  transition: 230ms cubic-bezier(0.21, 1.02, 0.73, 1);
  transform: translateY(0px);
  bottom: 0px;
  justify-content: center;
  z-index: 901;
  pointer-events: auto;
  opacity: 1;
  color: rgb(0 0 0 / 0.5);
  background-color: white;
  box-shadow: 0 0 5px 2px rgba(0,0,0, 0.3);
  border-radius: .5rem;
  max-width: 28rem;
  width: 100%;
  border: 0 solid #e5e7eb;
}
.animate-enter {
  animation: enter .2s ease-out;
}
.animate-leave {
  animation: leave .2s ease-out;
}
@keyframes enter {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes leave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}
.price-message__left {
  width: 0;
  padding: 0 .5rem;
  flex: 1 1 0%;
  display: flex;
  align-items: center;
}
.price-message__flex-start {
  display: flex;
  align-items: start;
}
.price-message__img-wrapper {
  margin: auto;
  flex-shrink: 0;
}
.price-message__img-wrapper img {
  display: block;
  max-width: 100%;
  width: 2.5rem;
  height: 2.5rem;
}
.price-message__text-wrapper {
  flex: 1 1 0%;
  margin-left: .75rem;
}
.price-message__text {
  padding: 0;
  margin: .7rem 0;
}
.price-message__button-wrapper {
  
  display: flex;
}
.price-message__button-wrapper button:nth-last-child(1) {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.price-message__button-wrapper button {
  color: rgb(79 70 229 / 1);
  font-weight: 500;
  font-size: .875rem;
    line-height: 1.25rem;
    padding: 1rem;
    border-color: transparent;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    padding: 10px;
    border-left: 1px solid rgb(229 231 235 / 1);
    transition: background-color .2s ease;
}
.price-message__button-wrapper button:hover {
  background-color: #e5e7eb;
}