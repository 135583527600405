.message {
  background-color: white;
  border-radius: 2rem;
  max-width: 1200px;
  padding: 100px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  a {
    cursor: pointer;
  }
  a:hover {
    color: black;
  }
}
.message__title {
  font-size: 80px;
  margin-top: 0;
  margin-bottom: 30px;
  user-select: none;
}
.message__img {
  width: max-content;
  height: max-content;
  user-select: none;
}
.message__email {
  font-size: 30px;
  user-select: none;
}
.message__text {
  font-size: 22px;
  text-align: left;
  width: 88%;
  margin: auto;
  user-select: none;
}
.message__pay {
  margin-top: 30px;
  font-size: 24px;
}
.message__pay a {
  margin-top: 40px;
  font-size: 24px;
  color: rgb(77, 77, 77);
}
.message__text-container span {
  white-space: nowrap;
  margin-top: 20px;
}
@media (max-width: 700px) {
  .message {
    padding: 40px;
    border-radius: 0;
  }
  .message__title {
    font-size: 40px;
  }
  .message__email {
    font-size: 22px;
  }
  .message__text {
    font-size: 16px;
  }
  .message__pay {
    font-size: 18px;
  }
}