.shadow {
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
}

.bg-video {
  position: relative;
  width: 100%;
  height: 578px;
  overflow: hidden;
  user-select: none;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.bg-video__overlay {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 90%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.bg-video__video-wrapper {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  z-index: 1;
  margin: auto;
}
.bg-video__video-wrapper::before {
  content: '';
  -webkit-box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 1) inset;
  -moz-box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 1) inset;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 1) inset;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-video__video {
  background-image: url('../../assets/img/bg_pod_video.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100vw * 0.5625);
  left: calc(0px - ((100vw - 100%) / 2));
  position: absolute;
  top: calc(50% - ((100vw * 0.5625) / 2));
  width: 100vw;
}

.bg-video__text-wrapper {
  position: relative;
  z-index: 6;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-video__content {
  width: 100%;
  max-width: 1170px;
  margin: 16px;
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bg-video__city {
  color: gray;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}

.bg-video__title {
  font-family: Oswald;
  color: white;
  text-transform: uppercase;
  font-size: 90px;
  line-height: 100px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 25px;
}

.bg-video__description {
  font-weight: 700;
  font-size: 22px;
  color: rgb(201, 201, 201);
  margin-bottom: 20px;
}

.bg-video__button-wrapper {
  margin-left: auto;
  z-index: 6;
}
.bg-video__button {
  font-size: 18px;
  width: 140px;
  height: 40px;
  border-radius: 10px;
  box-shadow: none;
  border: none;
  cursor: pointer;
  background: linear-gradient(-45deg, #ee7752, #ffd600, #c71e1e, #e6fd14);
  background-size: 400% 400%;
  animation: animate_gradient 7s ease infinite;
  transition: 2s ease-in-out;
  // background-color: #ffd600;
}
@keyframes animate_gradient {
  0% {
    background-position: 0% 50%;
    box-shadow: 0 0 0 0 rgba(253, 59, 0, 0.6);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(228, 25, 49, 0);
  }
  45% {
    box-shadow: 0 0 0 0 rgba(228, 25, 49, 0);
  }
  50% {
    background-position: 100% 50%;
    box-shadow: 0 0 0 0 rgba(253, 59, 0, 0.6);
  }
  75% {
    box-shadow: 0 0 0 10px rgba(228, 25, 49, 0);
  }
  95% {
    box-shadow: 0 0 0 0 rgba(228, 25, 49, 0);
  }
  100% {
    background-position: 0% 50%;
  }
}
.bg-video__button:hover {
  background-position: 100% 50%;
  animation: none;
  box-shadow: 0 0 5px 3px rgba(253, 59, 0, 0.6);
}

.bg-video__text-wrapper.render-animation {
  animation: smooth-render 1s ease;
}

@media (max-width: 1096px) {
  .bg-video__video {
    left: 0;
    min-height: 100%;
    top: 0;
    transform: translateX(-50%) translateX(50vw);
    width: calc(200vh / 0.5624);
  }
}

@media (max-width: 460px) {
  .bg-video {
    height: max-content;
  }

  .bg-video__content > p {
    font-size: 18px;
    text-shadow: none;
  }

  .bg-video__title {
    font-size: 44px;
    line-height: normal;
    margin-bottom: 16px;
    text-shadow: none;
  }

  .bg-video__description {
    margin-bottom: 0;
    text-shadow: none;
  }

  .bg-video__button {
    font-size: 11px;
    letter-spacing: 0px;
  }
}
